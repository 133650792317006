<a matButton
   routerLink="/subscribe"
   title="Suscríbase"
   *ngIf="!footer">
<!--  <mat-icon class="fa fa-envelope-open"></mat-icon>-->
</a>
<ng-container *ngFor="let link of links">
  <a matButton
     *ngIf="link.enabled"
     [href]="link.href"
     [title]="link.title"
     target="blanck">
<!--    <mat-icon [class]="link.icon"-->
<!--              *ngIf="!footer"></mat-icon>-->
<!--    <span *ngIf="footer">> {{link.title}}</span>-->
  </a>
</ng-container>
